export const latestUpdatesData = [
  {
    date: "Apr 8, 2024",
    info: "5 added",
    description: [
      {
        icon: ["FlipSimuImage"],
        title: "Account System",
        detail:
          "You can now sign up and log in to enjoy enhanced features for a more personalized and efficient experience! Navigate to Menu > Sign Up / Log In to begin using your account.",
      },
      {
        icon: ["FlipSimuImage"],
        title: "Save and Edit Files",
        detail:
          "As a signed-in user, you can save and edit up to five files per tool, and create unlimited non-editable shareable links from those files, Thus, your sign-in privileges are particularly beneficial for enjoying editing capabilities.",
      },
      {
        icon: ["FlipSimuImage"],
        title: "Personal Custom Colors",
        detail:
          "As a signed-in user, you now have the ability to select three custom color sets for all tools, enhancing your experience with a personalized and visually appealing user interface.",
      },
      {
        icon: ["FlipSimuImage"],
        title: "Shared & Embed Links List",
        detail:
          "As a signed-in user, all created share and embed links are saved to your account for later use and can be deleted anytime. To view share links, go to Share > View Share List. For embed links, visit the embed page and click History in the header.",
      },
      {
        icon: ["TarotImage", "NumberImage"],
        title: "Share Link",
        detail:
          "You can now create share links for both Tarot Flip Simu and Number Flip Simu, enhancing your sharing capabilities.",
      },
    ],
  },
  {
    date: "Feb 5, 2024",
    info: "2 updated",
    description: [
      {
        icon: ["CoinImage", "DiceImage"],
        title: "New Style Section",
        detail:
          "We have simplified customization by introducing a 'Style' section. Our tools now allow each coin and D6 dice to have its own color and have increased their quantity limit to 20 each, while other dice types (D4, D8, D10, D12, and D20) are limited to 3 each.",
      },
      {
        icon: ["FlipSimuImage"],
        title: "New Layout",
        detail:
          "We have introduced an enhancement to our layout, presenting a wider view for all tools, aimed at improving user experience.",
      },
    ],
  },
  {
    date: "Nov 16, 2023",
    info: "1 added",
    description: [
      {
        icon: ["NumberImage"],
        title: "Number Flip Simu",
        detail:
          "We have launched new tool Number Flip Simu which is a number card flipper. Let's start flip & generate a random number card in a fun way!",
      },
    ],
  },
  {
    date: "Aug 18, 2023",
    info: "2 updated",
    description: [
      {
        icon: ["FlipSimuImage"],
        title: "Rebranded Tools",
        detail: `Introducing our updated tool names: Coin Flip Simu, Dice Roll Simu, and Tarot Flip Simu. Rest assured, the functions you love remain unchanged.`,
      },
      {
        icon: ["FlipSimuImage"],
        title: "Tutorial Section",
        detail: `We have updated the tutorial content below the tool section. Dive in to enhance your understanding.`,
      },
    ],
  },
  {
    date: "Jul 20, 2023",
    info: "1 updated",
    description: [
      {
        icon: ["FlipSimuImage"],
        title: "Intuition Test and Luck Test Into Individual Pages",
        detail: `The Intuition Test and Luck Test games are made to become individual pages themselves.`,
      },
    ],
  },
  {
    date: "Jun 8, 2023",
    info: "2 updated",
    description: [
      {
        icon: ["CoinImage"],
        title: "Reintroduced Intuition Test and Luck Test",
        detail: `We have brought back "Test Your Intuition" and "Test Your Luck" functions with a new button for selection.`,
      },
      {
        icon: ["FlipSimuImage"],
        title: "Feedback Page",
        detail: `We have implemented a new feedback page on our website.`,
      },
    ],
  },
  {
    date: "May 4, 2023",
    info: "1 added, 3 updated",
    description: [
      {
        icon: ["FlipSimuImage"],
        title: "Background Color",
        detail:
          "You can now change the background color from the customization.",
      },
      {
        icon: ["FlipSimuImage"],
        title: "Minor Layout Change",
        detail: "We have made minor layout improvement on the website.",
      },
      {
        icon: ["FlipSimuImage"],
        title: "Embed Tool Direction",
        detail:
          "You can now change the embed tool direction in either horizontal or vertical.",
      },
      {
        icon: ["CoinImage"],
        title: "Temporary Removed Intuition Test and Luck Test",
        detail: `We have removed "Test Your Intuition" and "Test Your Luck" functions.`,
      },
    ],
  },
  {
    date: "Feb 21, 2023",
    info: "1 updated",
    description: [
      {
        icon: ["FlipSimuImage"],
        title: "Minor Layout Change",
        detail:
          "We have made minor layout improvement on the website header, besides added expand and collapse tool box function.",
      },
    ],
  },
  {
    date: "Nov 3, 2022",
    info: "1 added, 1 updated",
    description: [
      {
        icon: ["CoinImage", "DiceImage"],
        title: "Press SPACEBAR to Flip or Roll",
        detail:
          "You can now press your desktop's SPACEBAR to trigger the flip or roll.",
      },
      {
        icon: ["Station2048Image"],
        title: "2048 Guru",
        detail:
          "Sorry to say that we no longer support FS 2048 and remove it from our site. If you wish to play 2048 game, please go to this site, https://2048.guru/",
      },
    ],
  },
  {
    date: "Sep 21, 2022",
    info: "1 added",
    description: [
      {
        icon: ["CoinImage", "DiceImage"],
        title: "Embed Tools",
        detail:
          "We have added embed feature for Coin Flip Simu and Dice Roll Simu. You can now embed tool link on your blog, website, or power point.",
      },
    ],
  },
  {
    date: "Aug 29, 2022",
    info: "1 updated",
    description: [
      {
        icon: ["FlipSimuImage"],
        title: "New Layout",
        detail:
          "We have made new layout improvement for Coin Flip Simu, Dice Roll Simu, Tarot Flip Simu. You can now show and hide the settings bar and get wider tool view.",
      },
    ],
  },
  {
    date: "Aug 2, 2022",
    info: "5 added, 1 updated",
    description: [
      {
        icon: ["DiceImage"],
        title: "5 Types of Dice",
        detail:
          "We added other most often used dices for Dice Roll Simu tool, which are D4, D8, D10, D12 and D20. Besides, we added number shape for D6 as well.",
      },
      {
        icon: ["TarotImage"],
        title: "Tarot Cards Description",
        detail:
          "We updated more detailed explanation on the tarot cards for your better understanding.",
      },
    ],
  },
  {
    date: "Jul 22, 2022",
    info: "1 updated",
    description: [
      {
        icon: ["CoinImage"],
        title: "Global Stats",
        detail:
          "We detected minor issue happen in Coin Flip Simu global stats on 23 May, and have fixed it.",
      },
    ],
  },
  {
    date: "Jun 30, 2022",
    info: "1 updated",
    description: [
      {
        icon: ["TarotImage"],
        title: "User Interface Improvement",
        detail:
          "We improved the user interface and your experience when using the Tarot Flip Simu.",
      },
    ],
  },
  {
    date: "Jun 22, 2022",
    info: "1 updated",
    description: [
      {
        icon: ["TarotImage"],
        title: "Minor Improvement",
        detail:
          "We updated Tarot Flip Simu to speed up rendering by caching the tarot cards.",
      },
    ],
  },
  {
    date: "Jun 21, 2022",
    info: "1 added, 1 updated",
    description: [
      {
        icon: ["TarotImage"],
        title: "Tarot Flip Simu",
        detail: "We added Yes or No Tarot Flip Simu tool in the website.",
      },
      {
        icon: ["FlipSimuImage"],
        title: "Minor Layout Change",
        detail: "We updated logo and did some page layout change.",
      },
    ],
  },
  {
    date: "May 22, 2022",
    info: "1 updated",
    description: [
      {
        icon: ["CoinImage", "DiceImage"],
        title: "Separated Setting",
        detail:
          "We have separated setting for each of the coins and dices. You can now set different content, texts and images for each of them.",
      },
    ],
  },
  {
    date: "Mar 23, 2022",
    info: "1 updated",
    description: [
      {
        icon: ["FlipSimuImage"],
        title: "Tutorial Section",
        detail:
          "We have made major tutorial update for Coin Flip Simu and Dice Roll Simu. You can read the content to understand more on the tools.",
      },
    ],
  },
  // {
  //   date: "Jan 17, 2022",
  //   info: "1 added",
  //   description: [
  //     {
  //       icon: ["Coin2048Image"],
  //       title: "FS 2048",
  //       detail:
  //         "We have launched new tool FS 2048 which is a game based on coins. Let's start stacking the identical coins to get a 2048 coin at the end!",
  //     },
  //   ],
  // },
  {
    date: "Nov 15, 2021",
    info: "1 updated",
    description: [
      {
        icon: ["CoinImage", "DiceImage"],
        title: "Share Link",
        detail:
          "You can now share your existing settings for Coin Flip Simu and Dice Roll Simu with the URL directly or through Facebook or Twitter.",
      },
    ],
  },
  {
    date: "Since Long Time Ago",
    info: "1 added",
    description: [
      {
        icon: ["FlipSimuImage"],
        title: "FlipSimu",
        detail:
          "We created the FlipSimu site. Fixing bugs, adding new features, and improving them since then.",
      },
    ],
  },
]
